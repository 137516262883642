import { css } from '@emotion/react'
import { Button, Loader } from '@mantine/core'
import { Dayjs } from 'dayjs'
import { forEach, isEmpty, map } from 'lodash'
import { Fragment } from 'react'
import useSWR from 'swr'
import { proxy, ref } from 'valtio'
import { apirc } from '~/configs/apirc'
import { ArgUpperCase } from '~/decorators/ArgUpperCase'
import { getSymbolFromTo } from '~/modules/monitor/getSymbolFromToUtil'
import {
  twAMFuturesTimeRange,
  twPMFuturesTimeRange,
  twStockTimeRange,
} from '~/modules/monitor/specTimeRangeUtil'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'
import { FrInstrumentOfSymbol } from '~/pages/heineken_template/_fr/fr_instrument/_OfSymbol'
import {
  FrInstrumentExchange,
  FrInstrumentType,
} from '~/pages/heineken_template/_fr/fr_instrument/_types'
import { Preset_EntirePage } from '~/pages/heineken_template/_preset/preset_EntirePage'
import { component } from '~/utils/component'
import dayAPI from '~/utils/dayAPI'
import { isOpbsSymbol, optionTranslator } from '~/utils/optionsTranslator'

class FrInstrument {
  /**
   * 前端 memory cache
   *
   * - 已被程式問過的 `OfSymbol` 會存於此
   * - - 尚未程式問過的，暫且 `undefined`
   * - - 在設計上，在你問的當下瞬間就會有存好值並返給你
   * - - 在設計上，完全無值 `undefined` 的情況，只有完全找不到相關才會發生
   */
  symbols: {
    [symbol: string]: FrInstrumentOfSymbol
  } = {}

  /**
   * 開收盤時間
   *
   * - 以台灣時間為主，所有時區時差皆已經校準台灣時間
   * - - 參考 {@link FrInstrumentOfSymbol.isSessionOpen}
   *
   * @example
   *   //
   *   // 一般而言，正常使用
   *   const CN1 = fr_instrument.getSymbol('CN-1')
   *
   *   CN1.isSessionOpen === false // 當台灣時間 08:59
   *   CN1.isSessionOpen === true // 當台灣時間 09:00
   *
   *   CN1.isSessionOpen === true // 當台灣時間 16:34
   *   CN1.isSessionOpen === false // 當台灣時間 16:35
   *
   * @example
   *   //
   *   const CN1_sessions = fr_instrument.getSessions('CN-1') // [{ from, to }, { from, to }]
   */
  @ArgUpperCase()
  getSessions(symbol: string): {
    from: Dayjs
    to: Dayjs
  }[] {
    const type = fr_instrument.getType(symbol)
    const exchange = fr_instrument.getExchange(symbol)

    // 先查找遠端資料是否提供
    const sessionsCache = fr_instrument._cache.symbolToSessionsMapp[symbol]
    if (!isEmpty(sessionsCache)) {
      // 將遠端資料統一轉前端 dayjs 資料
      return sessionsCache.map(session => {
        const now = dayAPI()
        const todayAsPrefix = now.format('YYYY-MM-DD')

        //
        // 不在乎前面的「年月日」，因為後端也沒有給「年月日」
        // 所以我們只取時間，年月日我們讓它都是今天
        // 並且對於時差問題，皆以台灣時間為主軸來校準轉譯
        // 方便往後 UI層 實現可以「以台灣時間直接來判斷」
        const from = dayAPI(`${todayAsPrefix} ${session.open_time}:00`)
          .tz(session.timezone, true)
          .tz('Asia/Taipei')
          .set('days', now.get('days')) // 為了撫平不同正負時區會使日期偏差，需要以台灣日期校準

        const to = dayAPI(`${todayAsPrefix} ${session.close_time}:00`)
          .tz(session.timezone, true)
          .tz('Asia/Taipei')
          .set('days', now.get('days'))

        /**
         * 如有跨日的話，我們再讓 to 加一天
         *
         * 如此當前端在使用 `now()` 來判斷 `isBetween(from, to)` 時，更佳方便
         */
        return {
          from,
          to: to.isBefore(from) ? to.add(1, 'day') : to,
        }
      })
    }

    // 依前端維護
    if (symbol.match(/TF-\d/)) {
      return [covertToFromToObject(twAMFuturesTimeRange(dayAPI()))]
    }

    if (symbol.match(/T[EX]-\d/)) {
      return [
        covertToFromToObject(twPMFuturesTimeRange(dayAPI())), // session1 是 1500 開盤
        covertToFromToObject(twAMFuturesTimeRange(dayAPI())),
      ]
    }

    // 依條件推斷
    if (type === FrInstrumentType.台灣股票 && exchange === FrInstrumentExchange.TWSE) {
      return [covertToFromToObject(twStockTimeRange(dayAPI()))]
    }

    // 最後查找
    const fallback = getSymbolFromTo(symbol.toUpperCase(), dayAPI())

    return fallback ? [covertToFromToObject(fallback)] : []
  }

  /**
   * 商品類型
   *
   * @example
   *   //
   *   fr_instrument.getType('CDF-1') // 'futures' // 股指 期貨
   *   fr_instrument.getType('TX-1') // 'futures' // 台指 期貨
   *   fr_instrument.getType('ES-1') // 'futures' // 標普 期貨
   *   fr_instrument.getType('JY-1') // 'futures' // 日圓 期貨
   */
  @ArgUpperCase()
  getType(symbol: string) {
    if (!isEmpty(fr_instrument._cache.symbolOfStockToNameMapp[symbol])) {
      return FrInstrumentType.台灣股票
    }

    const symbolToType = fr_instrument._cache.symbolToTypeMapp[symbol]
    if (!isEmpty(symbolToType)) {
      return symbolToType as FrInstrumentType
    }

    if (isOpbsSymbol(symbol)) {
      return FrInstrumentType.台灣選擇權
    }

    // 判斷 tvAPI 的 type

    return FrInstrumentType.未知
  }

  /**
   * 拿「交易所名字」
   *
   * @example
   *   //
   *   fr_instrument.getExchange('2330') // 'TWSE'
   *   fr_instrument.getExchange('!@#$%^&*') // ''
   */
  @ArgUpperCase()
  getExchange(symbol: string) {
    // 先查找是否台灣股票交易所
    if (!isEmpty(fr_instrument._cache.symbolOfStockToNameMapp[symbol])) {
      return FrInstrumentExchange.TWSE
    }

    if (isOpbsSymbol(symbol)) {
      return FrInstrumentExchange.TFE
    }

    // 查找期貨交易所
    const exchangeFromTvAPI = this._cache.symbolToExchangeMapp[symbol]
    if (!isEmpty(exchangeFromTvAPI)) {
      return exchangeFromTvAPI
    }

    return FrInstrumentType.未知
  }

  /**
   * @example
   *   fr_instrument.getName('2330') // '台積電'
   *   fr_instrument.getName('TX118000M2') // '台指1月W1選18000Put'
   */
  @ArgUpperCase()
  getName(symbol: string) {
    // 先從第一優先字典裡查找
    let name = fr_instrument._cache.symbolToNameMapp[symbol]

    // 查找台灣股票代碼
    if (!name) name = fr_instrument._cache.symbolOfStockToNameMapp[symbol]
    // 查找台灣選擇權
    if (!name) name = optionTranslator(symbol)
    // 都找不到，以原投入 symbol 返回
    if (!name) name = symbol

    return name
  }

  /**
   * @example
   *   //
   *   // 飯粒
   *   const tsm = fr_instrument.getSymbol('2330')
   *
   *   tsm.name // '台積電'
   *   tsm.code // '2330'
   *   tsm.is.type.option // false
   *   tsm.is.type.stock // true
   */
  @ArgUpperCase()
  getSymbol(symbol: string) {
    if (!fr_instrument.symbols[symbol]) {
      fr_instrument.symbols[symbol] = proxy(
        new FrInstrumentOfSymbol({
          symbol,
        }),
      )
    }

    return fr_instrument.symbols[symbol]
  }

  /** 取得每點價值 */
  @ArgUpperCase()
  getBigPointValue(symbol: string) {
    const value = this._cache.symbolToBigPointValue[symbol]

    if (!value) {
      /** E.g. `TX-1` */
      if (symbol.includes('TX')) {
        return 200
      }
      /** E.g. `0050` `0051` */
      if (symbol.match(/[\d]{4}/)) {
        return 1000
      }
    }

    return value || 0
  }

  /**
   * @example
   *   //
   *   // 如果你要一次多代碼
   *   const symbolList = fr_instrument.getManySymbols(['2330'])
   *
   *   const tsm = symbolList[0]
   *
   *   tsm.name // '台積電'
   *   tsm.code // '2330'
   *   tsm.is.type.option // false
   *   tsm.is.type.stock // true
   */
  getManySymbols(symbols: string[]) {
    return map(symbols, code => fr_instrument.getSymbol(code))
  }

  /** 是否具備股期 */
  @ArgUpperCase()
  hasFutures(symbol: string) {
    const futuresSymbolString = fr_instrument._cache.symbolOfStockFutures[symbol] || ''
    if (futuresSymbolString.length) return true

    return false
  }

  /** 向後端問數據並存於 memory as cache */
  async installData() {
    //
    // 台灣股票代碼
    if (isEmpty(fr_instrument._cache.symbolOfStockToNameMapp)) {
      debugAPI.fr_instrument.log(`台灣股票代碼: 正在準備必要數據...`)
      const symbolOfStockToNameMapp = await apirc.staticJson.fetchSymbolOfStockToNameMapp()

      fr_instrument._cache.symbolOfStockToNameMapp = symbolOfStockToNameMapp
    }

    //
    // 期貨代碼
    if (isEmpty(fr_instrument._cache.symbolToNameMapp)) {
      debugAPI.fr_instrument.log(`期貨代碼: 正在準備必要數據...`)
      const symbolToNameMapp = await apirc.staticJson.fetchSymbolToNameMapp()

      fr_instrument._cache.symbolToNameMapp = symbolToNameMapp
    }

    //
    // 商品的一些通用資訊
    if (isEmpty(fr_instrument._cache.symbolOfStockFutures)) {
      debugAPI.fr_instrument.log(`期貨代碼: 正在準備必要數據...`)
      const commonSymbolList = await apirc.staticJson.fetchCommonSymbolList()

      fr_instrument._cache.symbolOfStockFutures = commonSymbolList.stockFutures
    }

    // TvAPIs 的交易所、類型資料
    if (
      isEmpty(fr_instrument._cache.symbolToExchangeMapp) ||
      isEmpty(fr_instrument._cache.symbolToTypeMapp)
    ) {
      debugAPI.fr_instrument.log(`TvAPIs: 正在準備必要數據...`)
      //
      // TvAPIs 有的商品資訊
      const dataOfTvAPIs = await Promise.race([apirc.tvAPIs.fetchAll(), apirc.tvAPIsOfGCP.fetchAll()])

      //
      // TvAPIs 裡頭的「type字串」參考
      forEach(dataOfTvAPIs, info => {
        fr_instrument._cache.symbolToExchangeMapp[info.symbol] = info.exchange
        fr_instrument._cache.symbolToTypeMapp[info.symbol] = info.type.includes('_futures')
          ? FrInstrumentType.期貨
          : (info.type as FrInstrumentType)
      })
    }

    // 開收盤時間
    if (isEmpty(fr_instrument._cache.symbolToSessionsMapp)) {
      const contractInfo = await apirc.staticJson.fetchContractInfo()

      forEach(contractInfo, (info, symbol) => {
        fr_instrument._cache.symbolToBigPointValue[symbol] = info.big_point_value

        fr_instrument._cache.symbolToSessionsMapp[symbol] = info.trading_interval.map(value => ({
          ...value,
          timezone: info.timezone,
        }))
      })
    }
  }

  /**
   * 用於安裝到 Page 組件
   *
   * 可使必要的遠端數據，永遠比「UI 組件」的呈現早一步準備完成
   *
   * @example
   *   //
   *   return (
   *     <Suspense fallback={`必要資訊載入中...`}>
   *       <fr_instrument.DataProvider>
   *         <TemplatePage templateProps={...{}} />
   *       </fr_instrument.DataProvider>
   *     </Suspense>
   *   )
   */
  // DataProvider = ref(
  //   component<ReactProps>(props => {
  //     const { isReady } = useRouter()
  //     const [loading, setLoading] = useState(false)

  //     const hasReady = suspend(async () => {
  //       console.info(`${debugAPI.fr_instrument.logger.namespace} 正在準備必要遠端數據...`)
  //       await fr_instrument.installData()
  //       console.info(`${debugAPI.fr_instrument.logger.namespace} 準備完成，開始 render UI`)
  //       debugAPI.fr_instrument.log(`準備完成，開始 render UI`)

  //       return true
  //     }, [])

  //     useEffect(() => {
  //       if (isReady && hasReady) setLoading(true)
  //     }, [isReady, hasReady])

  //     return (
  //       <Fragment>
  //         {loading ? <Fragment>{props.children}</Fragment> : <Preset_EntirePage />}
  //       </Fragment>
  //     )
  //   }),
  // )

  DataProvider = ref(
    component<ReactProps>(props => {
      const isDataReady = useSWR(
        'FIXED_KEY',
        async function fetcher() {
          console.info(`${debugAPI.fr_instrument.logger.namespace} 正在準備必要遠端數據...`)
          try {
            await fr_instrument.installData()
            console.info(`${debugAPI.fr_instrument.logger.namespace} 準備完成，開始 render UI`)
            return true
          } catch (error: any) {
            console.debug(error?.message)
            return false
          }
        },
        {
          keepPreviousData: true,
          refreshWhenHidden: false,
          refreshWhenOffline: false,
          revalidateIfStale: false,
          revalidateOnFocus: false,
          errorRetryCount: 2,
          errorRetryInterval: 2500,
        },
      )

      return (
        <Fragment>
          {isDataReady.data === true && <Fragment>{props.children}</Fragment>}

          {isDataReady.data === false && (
            <Preset_EntirePage
              css={css`
                justify-content: center;
                align-content: center;
              `}
            >
              必要元件載入時發生問題
              <Button
                onClick={() => {
                  globalThis.location.reload()
                }}
              >
                點此重新整理
              </Button>
            </Preset_EntirePage>
          )}

          {isDataReady.data === undefined && (
            <Preset_EntirePage
              css={css`
                justify-content: center;
                align-content: center;
                transform: scale(1.5);
              `}
            >
              <Loader
                color='red'
                variant='bars'
              />
            </Preset_EntirePage>
          )}
        </Fragment>
      )
    }),
  )

  private _cache = {
    /**
     * 每點價值
     *
     * - E.g. 小台每點 50 新台幣
     * - E.g. 大台每點 200 新台幣
     * - E.g. 股票每點 1000 新台幣
     *
     * @example
     *   //
     *   toBe({
     *     'TX-1': 200,
     *     '0050': 1000,
     *   })
     */
    symbolToBigPointValue: {} as Record<string, number>,

    /**
     * 股票代碼
     *
     * - 只有台灣股票代碼
     * - 皆屬於交易所 `'TWSE'`
     *
     * @example
     *   //
     *   toBe({
     *     '2330': '台積電',
     *     '2886': '兆豐金',
     *     // ...更多, 略
     *   })
     */
    symbolOfStockToNameMapp: {} as Record<string, string>,

    /**
     * 股期代碼
     *
     * @example
     *   //
     *   toBe({
     *     '0050': 'NYF',
     *     '0056': 'PFF',
     *     '0061': 'NZF',
     *     '00636': 'OJF',
     *     '00639': 'OKF',
     *     '00643': 'OOF',
     *     '00878': 'RIF',
     *     '1101': 'DFF',
     *     '1102': 'DYF',
     *     '1210': 'DZF',
     *     '1216': 'CQF',
     *     '1301': 'CFF',
     *     '1303': 'CAF',
     *     '1312': 'EEF',
     *     '1314': 'EGF',
     *     '1319': 'EHF',
     *     '1326': 'DGF',
     *     '1402': 'CRF',
     *     '1440': 'EKF',
     *     '1476': 'LWF',
     *     '1477': 'KSF',
     *     // ...更多, 略
     *   })
     */
    symbolOfStockFutures: {} as Record<string, string>,

    /**
     * 期貨代碼
     *
     * @example
     *   //
     *   toBe({
     *     'TX-1': '台指期',
     *     'TXAM-1': '台指期(日盤)',
     *     'TXPM-1': '台指期(夜盤)',
     *     'TX-2': '台指期次月',
     *     // ...更多, 略
     *   })
     */
    symbolToNameMapp: {} as Record<string, string>,

    /**
     * 交易所資訊
     *
     * @example
     *   //
     *   toBe({
     *     'TX-1': 'TFE',
     *     'TXAM-1': 'TFE',
     *     'ES-1': 'CME',
     *     'TWN-1': 'SGX',
     *     // ...更多, 略
     *   })
     */
    symbolToExchangeMapp: {} as Record<string, string>,

    /**
     * 商品類型
     *
     * @example
     *   //
     *   toBe({
     *     TSE17: 'index',
     *     IBM: 'os_stock',
     *     NFLX: 'os_stock',
     *     TX413900N3: 'option',
     *     'TX-1': 'futures',
     *     'CDF-1': 'futures', // 轉譯由 'stock_futures',
     *     'FSTB-1': 'futures', // 轉譯由 'os_eurex_futures',
     *     'JY-1': 'futures', // 轉譯由 'os_fx_futures',
     *     'ES-1': 'futures', // 轉譯由 'os_index_futures',
     *     'TWN-1': 'futures', // 轉譯由 'os_index_futures',
     *     // ...更多, 略
     *   })
     */
    symbolToTypeMapp: {} as Record<string, string>,

    /**
     * 商品類型
     *
     * @example
     *   //
     *   toBe({
     *     'GC-1': [{ timezone: 'US/Eastern', open_time: '17:00', close_time: '16:00' }],
     *     'CN-1': [
     *       { timezone: 'Asia/Singapore', open_time: '09:00', close_time: '16:35' },
     *       { timezone: 'Asia/Singapore', open_time: '17:00', close_time: '05:15' },
     *     ],
     *     // ...更多, 略
     *   })
     */
    symbolToSessionsMapp: {} as Record<
      string,
      {
        timezone: string | 'Asia/Singapore' | 'US/Eastern' | 'Europe/Paris'
        open_time: string | '17:00' | '09:00'
        close_time: string | '16:35' | '05:15'
      }[]
    >,
  }
}

/**
 * `FrInstrument`
 *
 * - 跟 symbol 相關的所有邏輯入口
 *
 * @example
 *   //
 *   // 什麼是「symbol」？
 *   const symbolCode = '2330' // 這是 symbol
 *   // 別名：
 *   const symbol = '2330' // 這是 symbol
 *   const symbolNumber = '2330' // 這是 symbol
 *   const symbolString = '2330' // 這是 symbol
 *
 *   //
 *   // 什麼是「name」？
 *   const name = '台積電' // 這是 name
 *   // 別名：
 *   const displayName = '台積電' // 這是 name
 *   const symbolName = '台積電' // 這是 name
 */
export const fr_instrument = proxy(new FrInstrument())

function covertToFromToObject(unixValues: [number, number]) {
  return {
    from: dayAPI(unixValues[0] * 1000),
    to: dayAPI(unixValues[1] * 1000),
  }
}
