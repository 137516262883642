import { Dayjs } from 'dayjs'
import { find, flatMap, forEach, map, range, reduce } from 'lodash'
import { fr_instrument } from '~/pages/heineken_template/_fr/fr_instrument'
import {
  FrInstrumentExchange,
  FrInstrumentType,
} from '~/pages/heineken_template/_fr/fr_instrument/_types'
import dayAPI from '~/utils/dayAPI'

export class FrInstrumentOfSymbol {
  /** e.g. `'2330'` */
  readonly symbol

  /** e.g. `'台積電'` */
  readonly name

  readonly type

  /** 每點價值 */
  readonly bigPointValue

  readonly exchange: string | 'TWSE' | 'TFE'

  /**
   * `true` / `false` 快捷判斷
   *
   * @example
   *   //
   *   // 範例
   *   const TSE17 = fr_instrument.getSymbol('TSE17')
   *
   *   TSE17.is.type.index // true
   *   TSE17.is.type.stock // false
   *   TSE17.is.exchange.TWSE // true
   *   TSE17.is.exchange.CME // false
   */
  readonly is

  constructor(options: { symbol: string }) {
    this.symbol = options.symbol
    this.name = fr_instrument.getName(options.symbol)
    this.exchange = fr_instrument.getExchange(options.symbol)
    this.type = fr_instrument.getType(options.symbol)
    this.bigPointValue = fr_instrument.getBigPointValue(options.symbol)

    this.is = {
      type: reduce(
        FrInstrumentType,
        (returnValue, assertType) => {
          returnValue[assertType] = this.type === assertType
          return returnValue
        },
        {} as Record<FrInstrumentType, boolean>,
      ),
      exchange: reduce(
        FrInstrumentExchange,
        (returnValue, assertExchange) => {
          returnValue[assertExchange] = this.exchange === assertExchange
          return returnValue
        },
        {} as Record<FrInstrumentExchange, boolean>,
      ),
    }
  }

  at(index: number) {
    const target = fr_instrument.getSessions(this.symbol).at(index)

    if (!target) {
      throw new Error(`找不到 .at(${index}) 的 session`)
    }

    return target
  }

  /** 是否有股期 */
  get hasFutures() {
    return fr_instrument.hasFutures(this.symbol)
  }

  get sessions() {
    return fr_instrument.getSessions(this.symbol)
  }

  /**
   * 該商品現在「正在開盤時間區段之中」嗎？
   *
   * - 時間表示以台灣時間為主（時差已處理）例如美股開收盤：
   * - - 收盤 `'2023-08-01T05:00:00+08:00'` 清晨5點收盤
   * - - 開盤 `'2023-08-01T06:00:00+08:00'` 早上6點開盤
   * - 只驗了日常開收盤時間
   * - 沒有驗國定假日；例如沒有驗：日本天皇誕辰、農曆過年、1月1號跨年
   * - 沒有驗週六日
   */
  get isSessionOpen() {
    // console.debug('!!!🟡 ', {
    //   now: dayAPI().format('YYYY-MM-DD HH:mm:ss'),
    //   from: this.sessions[0].from.format('YYYY-MM-DD HH:mm:ss'),
    //   to: this.sessions[0].to.format('YYYY-MM-DD HH:mm:ss'),
    // })
    const sessions = fr_instrument.getSessions(this.symbol)

    return !!find(
      sessions,
      session => dayAPI().isSameOrAfter(session.from) && dayAPI().isBefore(session.to),
    )
  }
}
